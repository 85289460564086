<template>
  <div v-if="show" class="circle-wrapper">
    <ul class="loader-list">
      <li>
        <div class="loader-1 center"><span></span></div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "CircleLoader",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="scss">
.center {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.circle-wrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: black;
  opacity: 0.5;
}

.loader-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  clear: both;
}
.loader-list li {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
  display: block;
  width: calc(100% / 3);
  height: calc(100vh / 4);
}

.loader-1 {
  height: 32px;
  width: 32px;
  -webkit-animation: loader-1-1 4.8s linear infinite;
  animation: loader-1-1 4.8s linear infinite;
}
@-webkit-keyframes loader-1-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-1-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-1 span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  clip: rect(0, 32px, 32px, 16px);
  -webkit-animation: loader-1-2 1.2s linear infinite;
  animation: loader-1-2 1.2s linear infinite;
}
@-webkit-keyframes loader-1-2 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(220deg);
  }
}
@keyframes loader-1-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(220deg);
  }
}
.loader-1 span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  clip: rect(0, 32px, 32px, 16px);
  border: 3px solid blue;
  border-radius: 50%;
  -webkit-animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-1-3 {
  0% {
    -webkit-transform: rotate(-140deg);
  }
  50% {
    -webkit-transform: rotate(-160deg);
  }
  100% {
    -webkit-transform: rotate(140deg);
  }
}
@keyframes loader-1-3 {
  0% {
    transform: rotate(-140deg);
  }
  50% {
    transform: rotate(-160deg);
  }
  100% {
    transform: rotate(140deg);
  }
}
</style>
