<template>
  <t-modal v-model="show" header="Legend">
    <ul class="my-5 mx-3">
      <li class="flex justify-between items-center border-b border-gray-300">
        <span>{{ $t("legend_scheduled") }}</span>
        <div class="w-32 h-8" style="background-color: dodgerblue"></div>
      </li>
      <li class="flex justify-between items-center border-b border-gray-300">
        <span>{{ $t("legend_published") }}</span>
        <div class="w-32 h-8" style="background-color: orange"></div>
      </li>
    </ul>
    <template v-slot:footer>
      <div class="flex justify-end">
        <t-button type="button" class="text-sm" @click="show = false">{{
          $t("close")
        }}</t-button>
      </div>
    </template>
  </t-modal>
</template>

<script>
export default {
  name: "SchedulerLegendModal",
  props: ["value"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
