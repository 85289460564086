<template>
  <div class="py-1 rounded-md shadow-xs bg-white border">
    <a
      class="
        border-b
        uppercase
        font-semibold
        block
        px-4
        py-1
        text-xs
        leading-5
        text-gray-700
        transition
        duration-150
        ease-in-out
        hover:bg-gray-100
        focus:outline-none focus:bg-gray-100
      "
      role="menuitem"
      @click="$emit('selected', 'day')"
    >
      Delete this day
    </a>
    <a
      class="
        block
        uppercase
        font-semibold
        px-4
        py-1
        text-xs
        leading-5
        text-gray-700
        transition
        duration-150
        ease-in-out
        hover:bg-gray-100
        focus:outline-none focus:bg-gray-100
      "
      role="menuitem"
      @click="$emit('selected', 'period')"
    >
      Delete this period
    </a>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  position: relative;
  background: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  cursor: default;
}

div:before {
  content: "";
  position: absolute;
  top: 20px;
  z-index: 1;
  border: solid 10px transparent;
  border-right-color: #fff;
  box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  border-color: transparent transparent #fff #fff;
  transform: rotate(45deg);
}
</style>
