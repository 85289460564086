<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="md:w-3/4 mx-auto">
        <div class="flex items-center flex-wrap gap-4 mt-3">
          <div class="flex items-center justify-between w-100 mr-4">
            <label class="w-16 font-semibold mr-5">{{
              $t("horeca_location")
            }}</label>
            <t-select
              class="w-44"
              v-model="filterLocation"
              :options="locations"
              value-attribute="id"
              text-attribute="description"
            ></t-select>
          </div>
          <div class="flex items-center justify-between w-56">
            <label class="w-16 font-semibold">{{ $t("job_type") }}</label>
            <t-select
              class="w-44"
              v-model="filterJobType"
              :options="jobFilters"
              value-attribute="id"
              text-attribute="name"
            ></t-select>
          </div>
          <div class="ml-auto mt-4 flex flex-wrap md:mt-0 md:block">
            <button
              class="text-sm text-blue-500 w-1/2 mx-auto md:w-auto md:mx-4"
              @click="toggleSchedulerLegendModal = true"
            >
              <i class="fas fa-question-circle"></i>
              Help
            </button>
          </div>
        </div>
        <div class="wrapper">
          <div class="left">
            <div class="font-semibold text-lg mb-3">{{ $t("templates") }}</div>
            <div id="external-events" class="events-wrapper">
              <div v-if="templates.length">
                <div
                  class="flex-b box fc-event"
                  v-for="template in templates"
                  :key="template.template_name"
                  v-bind:templateId="template.template_id"
                  v-bind:horecaLocationId="template.horeca_location_id"
                >
                  <div>{{ template.template_name }}</div>
                </div>
              </div>
              <div v-else class="mt-3 text-sm">
                <p>{{ $t("scheduler_templates_empty") }}</p>
              </div>
            </div>
          </div>
          <div class="right">
            <circle-loader :show="calendarLoading"></circle-loader>
            <full-calendar
              ref="fullCalendar"
              id="calendar"
              :options="{ ...calendarOptions, events: calendarEvents }"
            />
          </div>
        </div>
        <scheduler-popover
          ref="schedulerPopover"
          v-click-outside="hidePopover"
          @selected="removeSelectedAction"
        ></scheduler-popover>
      </div>

      <scheduler-legend-modal v-model="toggleSchedulerLegendModal" />
    </template>
  </layout>
</template>

<script>
import CircleLoader from "@/components/CircleLoader.vue";
import Layout from "@/components/Layout.vue";
import SchedulerLegendModal from "@/components/SchedulerLegendModal.vue";
import SchedulerPopover from "@/components/SchedulerPopover.vue";
import { actions, getters } from "@/constants/state";
import { getOffset } from "@/utils/misc";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { find, get, map, size } from "lodash";
import moment from "@/utils/moment-utc";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

Vue.directive("click-outside", {
  bind: function (element, binding, vnode) {
    element.clickOutsideEvent = function (event) {
      if (!(element === event.target || element.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", element.clickOutsideEvent);
  },
  unbind: function (element) {
    document.body.removeEventListener("click", element.clickOutsideEvent);
  },
});

export default {
  components: {
    Layout,
    CircleLoader,
    SchedulerPopover,
    SchedulerLegendModal,
    FullCalendar,
  },

  data() {
    return {
      loading: false,
      calendarLoading: false,
      toggleSchedulerLegendModal: false,
      selectedDate: null,
      selectedEvent: null,
      calendarOptions: {
        initialView: "dayGridMonth",
        plugins: [dayGridPlugin, interactionPlugin],
        displayEventTime: false,
        dayMaxEvents: true,
        fixedWeekCount: false,
        showNonCurrentDates: false,
        editable: true,
        droppable: true,
        eventReceive: this.eventReceive,
        eventResize: this.eventHandle,
        eventDrop: this.eventHandle,
        dateClick: this.dateClick,
        eventDidMount: this.eventDidMount,
        firstDay: 1,
        customButtons: {
          prev: {
            text: "PREV",
            click: () => {
              this.prevButton();
            },
          },
          next: {
            text: "NEXT",
            click: () => {
              this.nextButton();
            },
          },
          today: {
            text: "Today",
            click: () => {
              this.todayButton();
            },
          },
        },
      },
    };
  },

  async mounted() {
    const containerEl = document.getElementById("external-events");
    this.$refs.schedulerPopover.$el.style.visibility = "hidden";

    new Draggable(containerEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        return {
          title: eventEl.innerText,
          template_id: eventEl.getAttribute("templateId"),
          horeca_location_id: eventEl.getAttribute("horecaLocationId"),
          stick: true,
        };
      },
    });

    const eventsWrapper = document.getElementById("external-events");
    const calendarWrapper = document.getElementById("calendar");

    eventsWrapper.style.height = calendarWrapper.offsetHeight - 30 + "px";

    this.loadAvailableTemplates();
    this.loadEvents();
  },

  methods: {
    ...mapActions({
      setSchedulerFilterAction: actions.SESSION_SET_SCHEDULER_FILTER_ACTION,
      getEvents: actions.TEMPLATE_SCHEDULES_FETCH_EVENTS_ACTION,
      getTemplatesByLocation: actions.TEMPLATE_LOCATIONS_FETCH_TEMPLATES_ACTION,
      upsertEvent: actions.TEMPLATE_SCHEDULES_UPSERT_ACTION,
      removeEvent: actions.TEMPLATE_SCHEDULES_REMOVE_ACTION,
    }),

    prevButton() {
      this.$refs.fullCalendar.getApi().prev();
      this.loadEvents();
    },

    nextButton() {
      this.$refs.fullCalendar.getApi().next();
      this.loadEvents();
    },

    todayButton() {
      this.$refs.fullCalendar.getApi().today();
      this.loadEvents();
    },

    hidePopover() {
      this.$refs.schedulerPopover.$el.style.visibility = "hidden";
    },

    async loadEvents() {
      this.calendarLoading = true;

      const date =
        this.$refs.fullCalendar.getApi().currentDataManager.state.dateProfile
          .currentRange;

      await this.getEvents({
        horecaLocationId: this.filter.horecaLocationId,
        jobFilterId: this.filter.jobFilterId,
        start: moment.utc(date.start).format("YYYY-MM-DD"),
        end: moment.utc(date.end).format("YYYY-MM-DD"),
      });

      this.calendarLoading = false;
    },

    async loadAvailableTemplates() {
      await this.getTemplatesByLocation({
        horecaLocationId: this.filter.horecaLocationId,
      });
    },

    async eventReceive(event) {
      const calendarEvent = this.$refs.fullCalendar
        .getApi()
        .getEventById(get(event, "event.id"));

      const startDate = moment(calendarEvent.start).format("YYYY-MM-DD");
      const endDate =
        calendarEvent.end === null
          ? startDate
          : moment(calendarEvent.end).format("YYYY-MM-DD");

      if (
        size(
          this.getOverlapTemplateEvents(
            startDate,
            endDate,
            get(calendarEvent, "extendedProps.template_id")
          )
        ) > 0
      ) {
        const { isOk } = await this.$dialog.confirm(
          "Update event?",
          this.$i18n.t("scheduler_templates_overlap"),
          "question"
        );

        if (isOk) {
          this.updateCalendar(calendarEvent, endDate);
        }

        calendarEvent.remove();
      } else if (size(this.getOverlapScheduleEvents(startDate, endDate)) <= 0) {
        this.addCalendar(calendarEvent);
      } else {
        this.$dialog.alert(
          "Duplicate event",
          this.$i18n.t("scheduler_templates_same"),
          "info"
        );

        calendarEvent.remove();
      }
    },

    async eventHandle(event) {
      const calendarEvent = this.$refs.fullCalendar
        .getApi()
        .getEventById(get(event, "event.id"));

      const startDate = moment(calendarEvent.start).format("YYYY-MM-DD");
      const endDate =
        calendarEvent.end === null
          ? startDate
          : moment(calendarEvent.end).subtract(1, "d").format("YYYY-MM-DD");

      if (
        size(
          this.getOverlapTemplateEvents(
            startDate,
            endDate,
            get(calendarEvent, "extendedProps.template_id")
          )
        ) > 0
      ) {
        const { isOk } = await this.$dialog.confirm(
          "Update event?",
          this.$i18n.t("scheduler_templates_overlap"),
          "question"
        );

        if (isOk) {
          this.updateCalendar(calendarEvent, endDate);
        } else {
          calendarEvent.revert();
        }
      } else {
        this.updateCalendar(calendarEvent, endDate);
      }
    },

    dateClick(value) {
      const startDate = moment(value.date).format("YYYY-MM-DD");
      const endDate = moment(value.date).add(1, "d").format("YYYY-MM-DD");

      this.selectedDate = startDate;
      const event = this.getEvent(startDate, endDate);

      if (event) {
        this.selectedEvent = event;
        const eventEl = document.querySelector(
          `[data-date="${this.selectedDate}"]`
        );

        let x = getOffset(eventEl).left;
        let y = getOffset(eventEl).top;

        x = window.innerWidth > 1500 ? x + 130 : x + 110;

        this.$refs.schedulerPopover.$el.style = `
          position: absolute;
          left: ${x}px;
          top: ${y + 10}px;
          z-index: 1;
        `;
      }
    },

    async removeSelectedAction(action) {
      this.$refs.schedulerPopover.$el.style.visibility = "hidden";

      if (this.selectedDate && this.selectedEvent) {
        switch (action) {
          case "day": {
            const eventDates = [];
            eventDates.push(
              this.selectedEvent.extendedProps.dates[this.selectedDate]
            );

            const { isOk } = await this.$dialog.confirm(
              "Delete event?",
              this.$i18n.t("scheduler_templates_remove_date"),
              "question"
            );

            if (!isOk) {
              return;
            }

            const payload = {
              templateId: this.selectedEvent.extendedProps.template_id,
              horecaLocationId:
                this.selectedEvent.extendedProps.horeca_location_id,
              jobFilterId: this.jobFilter,
              date_id: this.selectedEvent.id,
              dates: eventDates,
            };

            this.calendarLoading = true;

            try {
              await this.removeEvent(payload);

              this.$refs.fullCalendar.getApi().refetchEvents();
              this.loadEvents();
            } catch (error) {
              console.error("DEBUG: remove event", error);
            }

            this.calendarLoading = false;

            break;
          }
          case "period": {
            const { isOk } = await this.$dialog.confirm(
              "Delete event?",
              this.$i18n.t("scheduler_templates_remove_period"),
              "question"
            );

            if (!isOk) {
              return;
            }

            const payload = {
              templateId: this.selectedEvent.extendedProps.template_id,
              horecaLocationId:
                this.selectedEvent.extendedProps.horeca_location_id,
              date_id: this.selectedEvent.id,
              dates: this.selectedEvent.extendedProps.dates,
            };

            this.calendarLoading = true;

            try {
              await this.removeEvent(payload);

              this.$refs.fullCalendar.getApi().refetchEvents();
              this.loadEvents();
            } catch (error) {
              console.error("DEBUG: remove event", error);
            }

            this.calendarLoading = false;

            break;
          }
        }
      }
    },

    eventDidMount(event) {
      const dates = get(event, "event.extendedProps.dates");

      if (dates) {
        map(dates, function (value) {
          const date = moment(value.date).format("YYYY-MM-DD");
          const eventEl = document.querySelector(`[data-date="${date}"]`);

          if (eventEl) {
            eventEl.style.cursor = "pointer";
            eventEl.setAttribute("rel", "popover");
          }
        });
      }
    },

    getEvent(startDate, endDate) {
      const calendarEvents = this.$refs.fullCalendar.getApi().getEvents();

      return find(calendarEvents, function (event) {
        const start = moment(event.start).format("YYYY-MM-DD");
        const end =
          event.end === null
            ? moment(event.start).add(1, "d").format("YYYY-MM-DD")
            : moment(event.end).format("YYYY-MM-DD");

        return startDate >= start && endDate <= end;
      });
    },

    getOverlapTemplateEvents(startDate, endDate, templateId) {
      const calendarEvents = this.$refs.fullCalendar.getApi().getEvents();

      return find(calendarEvents, function (event) {
        const start = moment(event.start).format("YYYY-MM-DD");
        const end =
          event.end === null
            ? moment(event.start).add(1, "d").format("YYYY-MM-DD")
            : moment(event.end).subtract(1, "d").format("YYYY-MM-DD");

        const eventTemplateId = event.extendedProps.template_id;

        return (
          templateId != eventTemplateId &&
          ((start <= startDate && end >= endDate) ||
            (start > startDate && start < endDate) ||
            (end > startDate && end < endDate) ||
            end === startDate ||
            start === endDate)
        );
      });
    },

    getOverlapScheduleEvents(startDate, endDate) {
      const calendarEvents = this.$refs.fullCalendar.getApi().getEvents();

      return find(calendarEvents, function (event) {
        const start = moment(event.start).format("YYYY-MM-DD");
        const end =
          event.end === null
            ? moment(event.start).add(1, "d").format("YYYY-MM-DD")
            : moment(event.end).subtract(1, "d").format("YYYY-MM-DD");

        return (
          event.extendedProps.dates &&
          ((start <= startDate && end >= endDate) ||
            (start > startDate && start < endDate) ||
            (end > startDate && end < endDate) ||
            end === startDate ||
            start === endDate)
        );
      });
    },

    async addCalendar(event) {
      if (
        typeof event.start !== "undefined" &&
        typeof event.end !== "undefined"
      ) {
        const startDate = moment(event.start).format("YYYY-MM-DD");
        let endDate = null;

        if (event.end == null) {
          endDate = moment(event.start).format("YYYY-MM-DD");
        } else {
          endDate = moment(event.end).format("YYYY-MM-DD");
        }

        if (startDate && endDate) {
          const payload = {
            templateId: get(event, "extendedProps.template_id"),
            horecaLocationId: get(event, "extendedProps.horeca_location_id"),
            jobFilterId: this.filter.jobFilterId,
            date: startDate,
            start: startDate,
            end: endDate,
          };

          this.calendarLoading = true;

          try {
            if (await this.upsertEvent(payload)) {
              const calendarEvent = this.$refs.fullCalendar
                .getApi()
                .getEventById(event.id);

              calendarEvent.remove();
              this.$refs.fullCalendar.getApi().refetchEvents();
              this.loadEvents();
            }
          } catch (error) {
            console.error("DEBUG: add event", error);
          }

          this.calendarLoading = false;
        }
      }
    },

    async updateCalendar(event, endDate) {
      if (
        typeof event.start !== "undefined" &&
        typeof endDate !== "undefined"
      ) {
        const startDate = moment(event.start).format("YYYY-MM-DD");

        if (endDate == null) {
          endDate = moment(event.start).format("YYYY-MM-DD");
        } else {
          endDate = moment(endDate).format("YYYY-MM-DD");
        }

        if (startDate && endDate) {
          const payload = {
            templateId: get(event, "extendedProps.template_id"),
            horecaLocationId: get(event, "extendedProps.horeca_location_id"),
            jobFilterId: this.filter.jobFilterId,
            date: startDate,
            start: startDate,
            end: endDate,
            template_end_date: get(event, "extendedProps.template_end_date"),
            dates: get(event, "extendedProps.dates"),
          };

          this.calendarLoading = true;

          try {
            await this.upsertEvent(payload);

            this.$refs.fullCalendar.getApi().refetchEvents();
            this.loadEvents();
          } catch (error) {
            console.error("DEBUG: add event", error);
          }

          this.calendarLoading = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      locations: getters.HORECA_LOCATIONS_GETTER,
      jobFilters: getters.JOBS_JOB_FILTERS_GETTER,
      filter: getters.SESSION_SCHEDULER_FILTER_GETTER,
      templates: getters.TEMPLATE_LOCATIONS_TEMPLATES_GETTER,
      scenarios: getters.SCENARIOS_OVERVIEW_GETTER,
      calendarEvents: getters.TEMPLATE_SCHEDULES_EVENTS_GETTER,
    }),

    filterLocation: {
      get() {
        return +this.filter.horecaLocationId;
      },
      set(horecaLocationId) {
        this.setSchedulerFilterAction({
          ...this.filter,
          horecaLocationId,
        });
      },
    },

    filterJobType: {
      get() {
        return +this.filter.jobFilterId;
      },
      set(jobFilterId) {
        this.setSchedulerFilterAction({
          ...this.filter,
          jobFilterId,
        });
      },
    },
  },
  watch: {
    filterLocation: {
      handler() {
        this.loadAvailableTemplates();
        this.loadEvents();
        this.$refs.fullCalendar.getApi().refetchEvents();
      },
    },

    filterJobType: {
      handler() {
        this.loadEvents();
        this.$refs.fullCalendar.getApi().refetchEvents();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin-top: 15px;
  color: white;
  background-color: #3788d8;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  cursor: grab;
}

.wrapper {
  display: flex;
  padding-top: 5%;
}

.events-wrapper {
  overflow-y: scroll;
  padding-right: 10px;
}

.left {
  flex: 0 0 20%;
  margin-right: 5%;
  padding-top: 1%;
}

.right {
  flex: 1;
}
</style>
